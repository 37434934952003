<template>  

    <div class="">

        <b-card title="Manual Wallet Topup">
            <b-spinner v-if="spinnerShow == true" style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow">
            </b-spinner>
                <b-row v-if="mainData == true"> 
                    <b-col cols="6">
                        <b-form-group
                            label="Select Seller"
                            label-for="select_seller"
                            description="seller_id --- company_name(brand)"
                        >
                        <v-select
                            v-model="selectedSellers"
                            placeholder="Select Seller"
                            :options="option2"
                            :key="componentKey"
                            id="select_seller"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group
                            label="Select Package"
                            label-for="select_package"
                            description="package name"
                        >
                        <v-select
                            v-model="selected_package"
                            placeholder="Select Package"
                            :options="option1"
                            :key="componentKey1"
                            id="select_package"
                        />
                        </b-form-group>
                    </b-col>
                   
                  
                    
                    

                    
                    <br><br><br>
                    <b-col cols="12">
                        <b-button
                        variant="primary"
                        type="submit"
                        @click.prevent="sendForm"
                        >
                        Submit
                        </b-button>
                    </b-col>
                </b-row>
           
       
        
        

            <!-- basic modal -->
            <b-modal
            id="modal-1"
            title="Upload File"
            ok-only
            ok-title="Upload"
            >
            
                <b-form-file
                multiple
            
                />
            
            </b-modal>

        
           


    
    
        </b-card>

        <b-card title="Manual Topup Transactions">
            <!-- table -->
            <vue-good-table
            ref="unverified-sellers"
            mode="remote"
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :isLoading="isLoadingProp" 
            
            :totalRows="totalRecords"
            @on-column-filter="onColumnFilter"
            :pagination-options="{
                enabled: true,
            }"
            @on-sort-change="onSortChange"
            >
            <div slot="loadingContent">
            <b-spinner
                ref="spinner_one"
                variant="primary"
                class="mr-1"
                type="grow"
                style="width: 4rem; height: 4rem;"
            />
            </div>
            
            <template
                slot="table-row"
                slot-scope="props"
            >

                <!-- Column: Name -->
                <!-- <span
                v-if="props.column.field === 'fullNamae'"
                class="text-nowrap"
                >
                <b-avatar
                    :src="props.row.avatar"
                    class="mx-1"
                />
                <span class="text-nowrap">{{ props.row.fullName }}</span>
                </span> -->

            
                <!-- Column: Action -->
                <span v-if="props.column.field === 'action'">
                    <span v-if="props.row.attachment_link != null">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-1"
                            v-b-modal.modal-2
                            v-on:click="adjustBtn(props.row.attachment_link)"
                            >
                            Open
                        </b-button>
                    </span>
                   
                </span>

            
                
                <span v-else>
                {{ props.formattedRow[props.column.field] }}
                </span>
            </template>

            <!-- pagination -->
            <template
                slot="pagination-bottom"
                slot-scope="props"
            >
                <div class="d-flex justify-content-between flex-wrap">

                <!-- page length -->
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                    Showing 1 to
                    </span>
                    <b-form-select
                    v-model="pageLength"
                    :options="pages"
                    class="mx-1"
                    @input="handlePageChange"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>

                <!-- pagination -->
                <div>
                    <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @change="handleChangePage"
                    >
                    <template #prev-text>
                        <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                        />
                    </template>
                    </b-pagination>
                </div>
                </div>
            </template>
            </vue-good-table>
    
        </b-card>

        <b-modal
          id="modal-2"
          title="Attachment"
          ok-only
          ok-title="Close"
      >
          <b-card-text>
              
              <b-img :src="this.attachments" fluid alt="Fluid image"></b-img>
             
          </b-card-text>
      </b-modal>

        
    </div>
    
    

</template>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script type="text/javascript" src="libs/FileSaver/FileSaver.min.js"></script>
<script type="text/javascript" src="libs/js-xlsx/xlsx.core.min.js"></script>
<script type="text/javascript" src="tableExport.min.js"></script>
<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdownItem, BDropdown,BCard,BButton,BTabs,BTab,BFormFile,BForm,BRow,BCol,BSpinner,BCardText,BImg,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import store from '@/store/index'
import pages from '@/router/routes/pages'
import Ripple from 'vue-ripple-directive'
import reportStore from '@/store'

import VuePapaParse from 'papaparse'

export default {
  components: {
    VueGoodTable,
    vSelect,
    BImg,
    BCardText,
    BSpinner,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormFile,
    BTab,
    BTabs,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BButton,
    VuePapaParse,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
    tabIndex: 0,
      allData:null,
      log: [],
      pageLength: 10,
      totalRecords:0,
      attachment:[],
      attachments:null,
      file:'',
      payment_reference:'',
      payment_method:'',
      selected_package:[],
      selectedSellers:[],
      option2:[],
      option1:[],
      isLoadingProp: false,
      datas:[],
      componentKey:0,
      componentKey1:0,
      mainData:true,
      spinnerShow:false,

      pages: ['10','20','50','100'],
      columns: [
        {
          label: 'Seller ID',
          field: 'seller_id',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Seller',
          field: 'company_name',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Package',
          field: 'package_name',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Is Active',
          field: 'is_active',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Start Date',
          field: 'start_date',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'End Date',
          field: 'end_date',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
    
    
    
       
      ],
      rows: [],
      serverParams: {
        // a map of column filters example: {name: 'john', age: '20'}
        columnFilters: {
        },
        sort: {
          field: '', // example: 'name'
          type: '', // 'asc' or 'desc'
        },
        page: 1, // what page I want to show
        perPage: 10, // how many items I'm showing per page
      },
      searchTerm: '',
    }
  },
  computed: {
    

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },

   created() {
    this.$http.post('get-sellers-all')
        .then(res => { 
        if(res.status == 200){
            for(var i=0;i<res.data.data.length;i++){
                // console.log(res.data.data)
                this.option2[i] = res.data.data[i].id+' --- '+res.data.data[i].company_name+'('+res.data.data[i].brand+')';
            }
            this.componentKey = 1;
        }
    }),

    this.$http.post('get-packages-all')
        .then(res => { 
        if(res.status == 200){
            for(var i=0;i<res.data.data.length;i++){
                // console.log(res.data.data)
                this.option1[i] = res.data.data[i].id+' --- '+res.data.data[i].package_name;
            }
            this.componentKey1 = 1;
        }
    }),

    this.$http.post('previous-seller-packages',{
        data:this.serverParams,
        })
        .then(res => { this.rows = res.data.data;
          this.totalRecords = res.data.totalRecords;
          this.updateAllData(res.data.data);
          this.isLoadingProp = false;
        })
          
  },
  
  methods: {

    updateAllData(data){
      this.allData = null;
      this.allData = data;
    },
    updateParams(newProps) {
        
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    // handleSearch(searching) {
    //   this.log.push(`The user searched for: ${searching}`)
    // },
    handleChangePage(page) {
      this.updateParams({page: page});
      this.log.push(`The user changed the page to: ${page}`)
      this.loadItems();
    },
    handlePageChange(active) {
      this.updateParams({perPage: active});
      this.log.push(`the user change page:  ${active}`)
      this.loadItems();
    },
    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
      this.log.push(`the user ordered:  ${params[0].type}`)
    },
    onColumnFilter(params) {
        this.updateParams(params);
        this.loadItems();
    },

    loadItems() {
      this.isLoadingProp = true;
        this.$http.post('previous-seller-packages',{
            data:this.serverParams,
        }).then(response => {
            //  this.totalRecords = response.totalRecords;
            this.totalRecords = response.data.totalRecords;
            this.rows = response.data.data;
            this.updateAllData(response.data.data);
            this.isLoadingProp = false;
        });
      
      
    },


    adjustBtn(data){
        this.attachments = data;
    },





    sendForm(){
        var formData = new FormData();
        formData.append('selectedSellers', this.selectedSellers);
        formData.append('selected_package', this.selected_package);
        this.mainData = false;
        this.spinnerShow = true;
        this.$http.post('seller-package-save', formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            // this.showTable = false;
            this.mainData = true;
            this.spinnerShow = false;

            if(res.data.error == '0'){
                this.selectedSellers = [];
                this.selected_package = '';

                this.loadItems();
            }
            this.toast((res.data.error == '1') ? 'danger' : 'success', res.data.message, 'Manual Topup Process'); 

            
        })
    },

    toast(variant = null, message = 'Toast body content', title = variant) {
				
        message = this.$createElement('strong', { class: 'mr-2' }, message)

        this.$bvToast.toast(message, {
            title: title,
            variant,
            solid: false,
        })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>